import React from 'react'
import '../styles/global.css'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '9b91523e304bc6069cd9498be6a1b781',
  plugins: [new BugsnagPluginReact(React)],
})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const onError = (event) => {
  // You can also provide an onError callback to run just on errors caught by
  // the error boundary. Maybe you want to attach some of the current state from
  // whatever model/store you're using (e.g redux)
  console.log('about to send this event', { event })
}

export default function App({ Component, pageProps }) {
  return (
    <ErrorBoundary onError={onError}>
      <Component {...pageProps} />
    </ErrorBoundary>)
}
